import { useState } from "react";

export default function CheckRankInput({
  onChange,
  onSearch,
}: {
  onChange?: Function;
  onSearch: Function;
}) {
  const [search, setSearch] = useState<string>("");
  return (
    <div className="border border-[#F1B27A] rounded-md flex flex-col lg:flex-row w-full">
      <input
        type="text"
        onChange={(evt: any) => {
          if (onChange) {
            onChange(evt.target.value);
          }
          setSearch(evt.target.value);
        }}
        className="bg-[#FCE8D5] text-[#6C6D76] h-10 px-4 min-w-[250px] w-full ring-0 border-0 top-0"
        placeholder="Enter wallet address/email"
      />
      <div
        className="bg-[#F9C744] ring-0 border-0 h-10 p-2 text-outline-brown font-cookie cursor-pointer text-white text-center w-full min-w-[160px]"
        onClick={() => {
          onSearch(search);
        }}
      >
        <div className="translate-y-[2px]">
          Check your rank
        </div>
      </div>
    </div>
  );
}
