import { Popover } from "@headlessui/react";
import "dayjs"
import { BodyPart, MongenRace, MongenStatType, Rarity } from "interfaces";
import GameConstant from "interfaces/game-constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, utils } from "services";
import Anim from "services/anim";

export const DetailMongenPage = ({ backUrl }: { backUrl: string }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [mongenInfo, setMongenInfo] = useState(null);

  useEffect(() => {
    if (!id) {
      return;
    }
    api.getMongenData(Number(id)).then((data) => {
      setMongenInfo(data);
    }).catch((err: any) => {
      console.log({ err })
    });
  }, [id])

  if (!mongenInfo) {
    return (
      <div></div>
    )
  }

  return (
    <div>
      <span className="text-event text-xl flex gap-2 mt-[24px] ml-[40px] cursor-pointer z-20 min-w-min max-w-min fixed" onClick={() => {
        nav(-1)
      }}>
        <img src={require("./assets/back_btn.png")} className="w-8 object-contain cursor-pointer" alt="back" />
        <div className="font-bold text-2xl cursor-pointer">
          Back
        </div>
      </span>
      <DetailMongen mongenInfo={mongenInfo} />
    </div>
  )
}

export const DetailMongen = ({ mongenInfo }: { mongenInfo: any }) => {
  const tabs = ["Description", "Troops", "DNA"]
  const [activeTab, setTab] = useState("Description")
  const basicSkill = mongenInfo?.skills[0];
  const ultimateSkill = mongenInfo?.skills[1];
  const troopInfos = mongenInfo?.troopInfos;
  const statsData = mongenInfo?.details.stats;
  const [anim, setAnim] = useState(<></>)

  useEffect(() => {
    setAnim(
      <Anim
        lock={[]}
        dna={mongenInfo.dna_raw}
        width={500}
        height={500}
        setAnim={null}
        anim={"Idle"}
      />
    )
  }, [])

  return (
    <div className="xl:grid xl:grid-cols-2 px-5 pb-10">
      <div className="w-full z-0">
        <div className="top-[15%] xl:left-[12%] self-center mx-auto xl:fixed z-0 max-h-[500px] self-center text-center force-canvas" style={{
          // @ts-ignore
          textAlign: "-webkit-center"
        }}>
          {anim}
        </div>
      </div>
      <div className="text-white xl:mt-10 mx-auto xl:w-5/6 xl:mx-0" style={{ zIndex: 9 }}>
        <div className="text-2xl text-center xl:text-left">{mongenInfo.description}</div>
        <div className="text-sm text-center xl:text-left mt-1">{mongenInfo.name}</div>
        <div className="mt-4 flex gap-2 justify-center xl:justify-start">
          <div className="star-container w-[100px]">
            {Array(mongenInfo.evolve).map((e, idx) => {
              return (
                <div key={`star-${idx}`}>
                  <img src={require("./assets/star-full.png")} alt="star" />
                </div>
              )
            })}
          </div>
          <div className="text-lg text-center xl:text-left">
            Level <span className="font-cookie-run text-[#e99f01]">1</span><span className="font-cookie-run ml-2">| 10</span>
          </div>
        </div>

        <div className="flex justify-start gap-10 text-xl mt-16">
          {tabs.map((tab) => {
            return (
              <div className={`custom-tab cursor-pointer ${activeTab === tab && "active"}`} key={tab} onClick={() => {
                setTab(tab);
              }}>
                <div className="tab-title">{tab}</div>
                {activeTab === tab && <div className="bottom-line"></div>}
              </div>
            )
          })}
        </div>

        {activeTab === "Description" && <div>
          <div className="font-bold text-lg mt-8">
            About
          </div>
          <div className="bg-[#1C2023] grid grid-cols-3 gap-4 p-6 rounded-xl w-full mt-4">
            <div>
              <div className="text-[#c4c4c4]">CLASS</div>
              <div className="mt-3 flex gap-2 text-lg font-bold">
                <img src={`/assets/images/race/${MongenRace[mongenInfo.dna_raw[BodyPart.Form][0]].toLowerCase()}.png`} alt="race" className="w-8 h-7" />
                {MongenRace[mongenInfo.dna_raw[BodyPart.Form][0]].toUpperCase()}
              </div>
            </div>
            <div>
              <div className="text-[#c4c4c4]">RARITY</div>
              <div className="mt-3 flex gap-2 text-lg font-bold">
                <img src={`/assets/images/rarity/${Rarity[mongenInfo.dna_raw[BodyPart.Aura][0]].toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7" />
                {Rarity[mongenInfo.dna_raw[BodyPart.Aura][0]].toUpperCase()}
              </div>
            </div>
            <div>
              <div className="text-[#c4c4c4]">BREED COUNT</div>
              <div className="mt-3 font-bold">
                {mongenInfo.details.about.find((e: any) => e.name === "Children")?.value} / {GameConstant.BreedingLimit[mongenInfo.dna_raw[BodyPart.Aura][0]]}
              </div>
            </div>
          </div>

          <div className="font-bold text-lg mt-8">
            Stats
          </div>
          <div className="bg-[#1C2023] grid grid-cols-2 gap-4 p-6 rounded-xl w-full mt-4">
            <div>
              <div className="w-full mb-10">{" "}</div>
              <div className="mb-2 flex gap-2">
                <img src="/assets/images/stats/hp.svg" alt="hp" />
                Health
              </div>
              <div className="mb-2 flex gap-2">
                <img src="/assets/images/stats/shield.svg" alt="hp" />
                Sturdiness
              </div>
              <div className="mb-2 flex gap-2">
                <img src="/assets/images/stats/magic.svg" alt="hp" />
                Magic
              </div>
              <div className="mb-2 flex gap-2">
                <img src="/assets/images/stats/intel.svg" alt="hp" />
                Aggression
              </div>
            </div>
            <div>
              <div className="grid grid-cols-3 gap-4 text-center">
                <div>Base</div>
                <div>Current</div>
                <div>Max</div>
              </div>
              {[MongenStatType.Health, MongenStatType.Sturdiness, MongenStatType.Magic, MongenStatType.Aggresion].map((type) => {
                return (
                  <div className="grid grid-cols-3 gap-4 mt-3.5 text-center">
                    <div>{statsData[type].base}</div>
                    <div className="text-[#e89f01]">{statsData[type].value}</div>
                    <div>{statsData[type].max}</div>
                  </div>
                )
              })}
            </div>
          </div>

          {/* <img src="/assets/test.png" alt="test" /> */}
          <div className="font-bold text-lg mt-8">
            Skills
          </div>
          <div className="bg-[#1C2023] p-6 rounded-xl w-full mt-4">
            <div>
              <div className="text-[#c4c4c4] font-bold mb-2">Basic skill</div>
              <div className="flex gap-6 flex-wrap w-full">
                {basicSkill.skillInfos?.map((skill: any) => {
                  return (
                    <Skill skill={skill} />
                  )
                })}
              </div>
            </div>
            <div className="mt-8">
              <div className="text-[#c4c4c4] font-bold mb-2">Ultimate skill</div>
              <div className="flex gap-6 flex-wrap w-full">
                {ultimateSkill.skillInfos?.map((skill: any) => {
                  return (
                    <Skill skill={skill} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        }

        {activeTab === "DNA" && <div>
          <div className="text-xl font-bold mt-4">DNA</div>
          <div className="bg-[#2C3640] grid grid-cols-2 gap-4 py-3 px-6 rounded-t-xl w-full mt-4 text-[13px]">
            <div>
              Body part
            </div>
            <div>
              Self
            </div>
          </div>
          <div className="bg-[#1C2023] grid grid-cols-2 gap-4 p-6 rounded-b-xl w-full text-[13px]">
            {[BodyPart.Form, BodyPart.Head, BodyPart.Eyes, BodyPart.Horns, BodyPart.Tail, BodyPart.Back].map((bodyPart) => {
              const rarity = utils.getDnaRarity(mongenInfo.dna_raw[bodyPart])
              return (
                <>
                  <div>
                    {BodyPart[bodyPart]}
                  </div>
                  <div className='flex gap-2'>
                    <img src={`/assets/images/race/${MongenRace[mongenInfo.dna_raw[bodyPart][0]].toLowerCase()}.png`} alt="race" className="w-8 h-7" />
                    <img src={`/assets/images/rarity/${rarity.toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7" />
                    {utils.getDnaName(mongenInfo.dna_raw[bodyPart])}
                  </div>
                </>
              )
            })}
          </div>
        </div>}


        {activeTab === "Troops" && <div>
          {troopInfos.map((e: any) => {
            if (!e) {
              return;
            }
            return (
              <div>
                <div className="bg-[#1C2023] p-6 rounded-xl mt-4 w-full max-w-[600px]">
                  <div>{e.name}</div>
                  <div className="flex gap-3 mt-3">
                    <div className="flex gap-3">
                      <div className="">
                        <div className="w-[124px] h-[128px] bg-black rounded-lg relative">
                          <img src={`/assets/images/rarity/${Rarity[e.rarity].toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7 absolute right-1 top-1" />
                          <img src={utils.getTroopImage(e.code)} className="w-[124px] h-[124px] self-center justify-center pt-[4px]" alt="troop" />
                        </div>
                      </div>
                      <div className="h-[128px]">
                        {e.skills.sort((s: any) => s.type === "Basic skill" ? -1 : 1).map((s: any) => {
                          return (
                            <Skill skill={s} classSize={"w-[60px] h-[60px]"} positionClass="tooltip-top-right" />
                          )
                        })}
                      </div>
                    </div>
                    <div className="w-full max-w-[500px]">
                      <div className="justify-center self-center py-3">
                        <div className="flex gap-4 justify-between">
                          <div className="flex gap-2">
                            <img src="/assets/images/stats/hp.svg" />
                            {e.Health?.toFixed(2)}
                          </div>
                          <div className="flex gap-2">
                            <img src="/assets/images/stats/shield.svg" />
                            {e.Sturdiness?.toFixed(2)}
                          </div>
                          <div className="flex gap-2">
                            <img src="/assets/images/stats/magic.svg" />
                            {e.Magic?.toFixed(2)}
                          </div>
                          <div className="flex gap-2">
                            <img src="/assets/images/stats/intel.svg" />
                            {e.Aggression?.toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#2e2e2e] flex justify-between px-2 py-2 my-1 rounded-lg">
                        <div>Cost</div>
                        <div className="flex gap-1">
                          <img src="/img/reward/food.png" alt="food" className="w-6 h-6" />
                          {e.training_cost}
                        </div>
                      </div>
                      <div className="bg-[#2e2e2e] flex justify-between px-2 py-2 my-3 rounded-lg">
                        <div>Time</div>
                        <div className="flex gap-2">
                          {e.training_time} s
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm mt-3 bg-[#2e2e2e] p-2 px-3 rounded-lg">{e.description}</div>
                </div>
              </div>
            )
          })}
        </div>}
      </div>
    </div >
  )
}


const Skill = ({ skill, classSize, positionClass }: any) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button>
            <div className={`${classSize ? classSize : "w-[80px] h-[80px]"} ${skill.isActive && "active-skill"} cursor-pointer rounded-xl`}>
              <img src={utils.getSkillImage(skill.code)} alt="skill" className="rounded-xl" />
            </div>
          </Popover.Button>
          <Popover.Panel className={`tooltip-skill rounded-xl ${positionClass ? positionClass : "tooltip-top-left"} `}>
            <div className="text-center p-2">{skill.name}</div>
            <div className="content rounded-xl pt-4 pb-3 px-3 mx-1 mb-2">
              <div className="grid grid-cols-2">
                <div>
                  <div>
                    <img src={utils.getSkillImage(skill.code)} alt="skill" className="w-[94px] mx-auto" />
                  </div>
                  {skill.additionIcons && <div className="flex justify-center mx-auto w-full gap-[2px] mt-2 w-full mx-auto">
                    {skill.additionIcons.map((i: string) =>
                      <img src={i} alt="skill" className="w-[30px]" />
                    )}
                  </div>
                  }
                </div>
                <div>{Object.keys(skill.stats).map((key) => {
                  return (
                    <div className="flex justify-between px-6 text-[13px] mb-2 text-[#BFBFBF]">
                      <div>{key.toUpperCase()}</div>
                      <div>{skill.stats[key]}</div>
                    </div>
                  )
                })}</div>
              </div>
              <div className="desc rounded-xl py-2 px-4 text-[13px] mt-3">{skill.description}</div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}