import { RewardEntity, RewardType, TicketRarity } from "interfaces"
import { useEffect, useState } from "react"
import { utils } from "services"

export const RewardsDisplay = ({ rewards, RewardLayout }: { rewards: RewardEntity[], RewardLayout: any }) => {
    const [component, setComponent] = useState(<></>);

    useEffect(() => {
        const component =
            rewards.map((reward: RewardEntity, idx: number) => {
                switch (reward.reward_type) {
                    case RewardType.LOCK_MAG:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="mag-lock.png" isMoney={true} />
                    case RewardType.UNLOCK_MAG:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="mag-icon.webp" isMoney={true} />
                    case RewardType.MSTR:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="mstr-icon.svg" isMoney={true} />
                    case RewardType.SOULCORE:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="soulcore-icon.svg" src={utils.getSoulcoreIcon(reward)} />
                    case RewardType.PLOT:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="plot-2-icon.svg" src={utils.getPlotIcon(reward)} />
                    case RewardType.EVOLUTION_ITEM:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="evo-item-icon.svg" />
                    case RewardType.BOX:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="box-icon.svg" src={utils.getBoxIcon(reward)} />
                    case RewardType.RerollRuneStone:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/reroll_rune.webp" />
                    case RewardType.NormalRuneStone:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/normal_rune.webp" />
                    case RewardType.NormalStone:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/normal.webp" />
                    case RewardType.RerollStone:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/reroll.webp" />
                    case RewardType.ElectiveStoneCO:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_co.webp" />
                    case RewardType.ElectiveStoneUC:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_uc.webp" />
                    case RewardType.ElectiveStoneRA:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_ra.webp" />
                    case RewardType.ElectiveStoneEP:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_ep.webp" />
                    case RewardType.ElectiveStoneLE:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_le.webp" />
                    case RewardType.ElectiveRuneStoneCO:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_rune_co.webp" />
                    case RewardType.ElectiveRuneStoneUC:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_rune_uc.webp" />
                    case RewardType.ElectiveRuneStoneRA:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_rune_ra.webp" />
                    case RewardType.ElectiveRuneStoneEP:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_rune_ep.webp" />
                    case RewardType.ElectiveRuneStoneLE:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} src="/assets/images/stone/elective_rune_le.webp" />
                    case RewardType.Pigment:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon="pigment.webp" />
                    case RewardType.DecorationList:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`/decor/${reward.decoration_code}.png`} />
                    case RewardType.Energy:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`energy.png`} />
                    case RewardType.FOOD:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`food.png`} />
                    case RewardType.BATTLEPASS_EXP:
                        return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`bp-exp.png`} />
                    case RewardType.TICKET:
                        switch (reward.rarity as any) {
                            case TicketRarity.Common:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-co.svg`} />
                            case TicketRarity.Uncommon:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-uc.svg`} />
                            case TicketRarity.Rare:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-ra.svg`} />
                            case TicketRarity.Epic:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-ep.svg`} />
                            case TicketRarity.Legendary:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-le.svg`} />
                            case TicketRarity.Global:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-gl.svg`} />
                            case TicketRarity.General:
                                return <RewardLayout key={`reward-layout-${idx}`} reward={reward} icon={`ticket-ge.svg`} />
                            default:
                                return (<div></div>)
                        }
                    default:
                        return (<div></div>)
                }
            })
        setComponent(<>{component}</>);
    }, [])

    return (
        <>
            {component}
        </>
    )
}