import CheckRankInput from "components/check-rank-input";
import RankItem from "components/rank-item";
import { EventEntity } from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api } from "services";
export interface RankItemInterface {
 rank: number;
 customer_id: number;
 name: string;
 identity: string;
 score: number;
}
export default function EventRank({
 eventId,
 search,
 eventInfo,
}: {
 eventInfo: EventEntity;
 eventId: number;
 search: string;
}) {
 const user = useSelector((state: RootState) => state.user);
 const [rank, setRank] = useState([]);
 const [loading, setLoading] = useState<Boolean>(true);
 const [address, setAddress] = useState<string>("");
 const [error, setError] = useState<string>("");
 const [top, setTop] = useState<any[]>([]);
 async function loadRankInfos() {
  if (!eventId) {
   return;
  }
  try {
   setLoading(true);
   if (search) {
    let rs = await api.post("/event/search-event-rank", {
     event_id: eventId,
     search: search,
    });
    setRank([rs]);
   } else {
    let rs = await api.post("/event/get-event-rank", { event_id: eventId });
    setRank(rs);
   }
  } finally {
   setLoading(false);
  }
 }
 async function loadClaimInfo() {
  if (!user?.id) {
   return;
  }
  let rs = await api.post("/event/get-event-claim-info", {
   event_ids: [eventId],
  });
 }
 useEffect(() => {
  let tmp: any[] = [];
  rank
   .filter((r: any) => r.rank <= 3)
   .map((r: any) => {
    tmp[r.rank - 1] = r;
   });
  setTop(tmp);
 }, [rank]);

 useEffect(() => {
  console.log({ eventInfo });
  if (new Date(eventInfo?.end_time) < new Date()) {
   loadClaimInfo();
  } else {
   console.log("not end");
  }
  loadRankInfos();
 }, [eventId, search, eventInfo]);
 function renderName(rank: any) {
  if (!rank) {
   return null;
  }
  return (
   <div className="text-white w-full">
    <p className="text-3xl font-semibold text-ellipsis whitespace-nowrap w-full lg:w-56 overflow-hidden lg:mx-auto text-right lg:text-center">
     {rank.name}
    </p>
    <p className="text-[#BBBDC6] mt-2 text-ellipsis whitespace-nowrap w-full lg:w-56 overflow-hidden lg:mx-auto text-right lg:text-center">
     {rank.identity}
    </p>
    <p className="text-3xl text-[#FFAE19] font-bold mt-4 text-right lg:text-center">
     {Number(rank?.score?.toFixed(2))}
    </p>
   </div>
  );
 }
 return (
  <div className="p-4">
   <div className="mt-4">
    {loading && <p className="text-center">Loading...</p>}
    {!loading && (
     <div>
      <div className="flex justify-between flex-col lg:flex-row gap-4">
       <div className="w-full order-2 lg:order-1 flex items-center lg:flex-col flex-row">
        <img
         src={require("./second.png")}
         className="lg:mt-10 h-32 lg:h-44 mx-auto"
        />
        {top[1] && renderName(rank[1])}
       </div>
       <div className="w-full order-1 lg:order-2 flex items-center lg:flex-col flex-row">
        <img src={require("./first.png")} className="h-32 lg:h-44 mx-auto" />
        {top[0] && renderName(rank[0])}
       </div>
       <div className="w-full order-3 flex items-center lg:flex-col flex-row">
        <img
         src={require("./third.png")}
         className="lg:mt-16 h-32 lg:h-44 mx-auto"
        />
        {top[2] && renderName(rank[2])}
       </div>
      </div>
      {rank
       .filter((e: any, index: number) => e.rank > 3)
       .map((r: any, index: number) => (
        <RankItem data={r} />
       ))}
     </div>
    )}
   </div>
  </div>
 );
}
