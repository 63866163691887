import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RootState } from "reducer/store";
import { useSelector } from "react-redux";
import { api, utils } from "services";
import { AUCTION_TYPE } from "config";
import ui from "../../services/ui";
import { Input, Button, Form, Tabs } from "antd";
import { socketAuction } from "services";
import AuctionImage from "../auction-image";
import TabDetail from "./tab-detail"
import { BodyPart, Rarity } from "interfaces";


const { TabPane } = Tabs

export default function AuctionDetailPage({ backUrl }: { backUrl: string }) {
  const user = useSelector((state: RootState) => state.user);
  const [form] = Form.useForm();
  const { id } = useParams();
  const nav = useNavigate();
  const location = useLocation();
  const [infoItem, setInfoItem] = useState<any>({})
  const [detailItem, setDetailItem] = useState<any>(null)
  const [topBids, setTopBids] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [text, setText] = useState<any>(utils.getCountdownText(infoItem?.end_time));
  const [itemUpdate, setItemUpdate] = useState<any>(null)
  const dna: any = utils.tryToParse(infoItem?.dna_raw || infoItem?.dna)
  const rarity = infoItem?.rarity_index || infoItem?.item_data?.rarity;
  const [auctionImg, setImage] = useState(<></>)

  const rules = {
    price: [
      {
        validator: async (_: any, value: any) => {
          let amount = infoItem?.current_price > 0 ? Number(Math.ceil(infoItem?.current_price * 110) / 100) : Number(Math.ceil(infoItem?.init_price * 110) / 100)
          amount = itemUpdate ? Number(Math.ceil(itemUpdate?.price * 110) / 100) : amount
          if (value == "") {
            return Promise.reject(new Error("This field is required"));
          }
          if (value >= Number(amount)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(`Amount must be greater than or equal to ${amount}`));
        },
      }
    ],
  }

  const getDetail = async () => {
    let apiPoint
    let url
    let isP2P
    switch (location.pathname.split("/")[1]) {
      case AUCTION_TYPE.auctionB2C:
        apiPoint = api.getBidItem
        url = "/auction-items/bid"
        isP2P = false
        break;
      case AUCTION_TYPE.auctionP2P:
        apiPoint = api.getBidItemP2P
        url = "/p2p-auction/bid"
        isP2P = true
        break;
    }
    const res = await apiPoint(id)
    let dna = res?.item_data?.dna_raw ? res?.item_data?.dna_raw : res?.item_data?.dna
    let troopList
    let res2
    if (dna) {
      dna = Array.isArray(dna) ? dna : JSON.parse(dna)
      troopList = res?.item_data?.troop_list ? res?.item_data?.troop_list : res?.item_data?.troops
      troopList = Array.isArray(troopList) ? troopList : JSON.parse(troopList)
      if (isP2P) {
        res2 = await api.getMongenData(Number(res?.item_data?.id))
      }
    } else res2 = await api.getPlotData(res?.item_data?.id)
    setDetailItem(res2)
    setInfoItem({ ...res, dna: dna, troopList: troopList, url: url, name: res.auction_name || res.item_data.name })
  }

  const getTopBid = async () => {
    let url
    switch (location.pathname.split("/")[1]) {
      case AUCTION_TYPE.auctionB2C:
        url = "/auction-items/bid-history"
        break;
      case AUCTION_TYPE.auctionP2P:
        url = "/p2p-auction/bid-history"
        break;
    }
    const res = await api.getTopBid(url, id)
    if (res[0]?.user_id == user?.id) form.setFieldValue("price", res[0]?.price)
    else form.setFieldValue("price", "")
    setTopBids(res)
  }

  const createBid = async (value: any) => {
    try {
      if (infoItem.end_time <= Date.now()) {
        ui.alertFailed("Time out!", "");
      } else {
        setIsLoading(true)
        let price = itemUpdate ? itemUpdate.price : (infoItem?.current_price > 0 ? infoItem?.current_price : infoItem?.init_price)
        await api.createBid(infoItem.url, infoItem._id, Number(value?.price), user?.id, price)
      }

    } finally {
      getDetail()
      getTopBid()
      form.resetFields();
      setIsLoading(false)
    }
  }

  const onCancelAuction = async () => {
    try {
      await api.cancelAuctionP2P({ user_id: user.id, id: infoItem._id })
    } finally {
      nav("/auction-p2p")
    }
  }

  const cancelAuction = () => {
    ui.confirm(<div className="text-[#E1E2E9] text-bold text-[18px] mb-[14px]">
      Are you want to cancel this auction?
      <div className="pt-[18px] text-normal text-[12px]">Notice: Creation fee will not be refunded</div>
    </div>,
      async () => {
        await onCancelAuction();
      })
  }

  const onClickBack = () => {
    // nav(-1)
    nav(backUrl)
    socketAuction.disconnectSocket()
  }

  const onChangePrice = (e: any) => {
    const price = utils.formatInputNumber(e.target.value.toString(), 2)
    form.setFieldValue("price", price)
  }

  const customInfoTab = (title: string) => {
    return (
      <div className="">
        <div className="text-[18px] font-semibold mt-[41px] mb-[10px]">{title}</div>
        <div className={`bottom-line hidden`}></div>
      </div>
    );
  }

  const getEarn = () => {
    let myPrice = form.getFieldValue("price")
    let currentPrice = itemUpdate ? itemUpdate.price : infoItem?.current_price > 0 ? infoItem?.current_price : infoItem?.init_price
    let percent = (myPrice - currentPrice) / (currentPrice * 10)
    percent = percent > 0.1 ? 0.1 : percent
    let earn = percent * myPrice
    let isInvaild = percent * 100 < 1
    return isInvaild ? null : `You'll earn ${Number((percent * 100).toFixed(2))}% (${Number(Math.round(earn * 100) / 100)} MSTR) if someone else bids higher than you`
  }

  const renderNote = () => {
    if (topBids[0]?.user_id != user?.id) {
      return form.getFieldValue("price") ? getEarn() : "Input bid to see payout."
    } return `You’ll earn ${Number(Math.round((topBids[0].back_amount - topBids[0].price) * 100) / 100)} MSTR if someone else bids higher than you`
  }

  const renderLevel = () => {
    let level = detailItem?.details?.about.find((el: any) => el.name == "Level")
    let evolution = detailItem?.details?.about.find((el: any) => el.name == "Evolution")
    let arrStar = []
    for (let i = 0; i < evolution?.value; i++) {
      arrStar.push(<div key={`star-${i}`}>
        <img src={require("./star-full.png")} alt="star" />
      </div>)
    }
    return <div className="my-4 flex gap-2 justify-center xl:justify-start text-white">
      {evolution && <div className="star-container w-[100px]">
        {arrStar}
      </div>}
      {level?.max && <div className="text-lg text-center xl:text-left">
        Level <span className="font-cookie-run text-[#e99f01]">{level?.value ?? 1}</span><span className="font-cookie-run ml-2">| {level?.max}</span>
      </div>}
    </div>
  }

  const renderListBids = useMemo(() => {
    return (<TabPane
      className={`info-tab`}
      tab={customInfoTab("Top Bidder")}
      key={1}
    >
      <div className={`bg-[#21262B] grid grid-cols-${infoItem.user_id ? 2 : 3} gap-4 py-3 px-6 rounded-t-xl w-full mt-4 text-[14px] text-[#E1E2E9]`}>
        <div>
          User
        </div>
        <div>
          Bid Price
        </div>
        {!infoItem.user_id && <div>
          Earned
        </div>}
      </div>
      <div className={`bg-[#1C2023] grid grid-cols-${infoItem.user_id ? 2 : 3} gap-4 p-6 rounded-b-xl w-full text-[14px] text-[#E1E2E9]`}>
        {topBids.length > 0 ? topBids?.map((el: any, index: number) => <>
          <div>
            {el?.user_name || el?.user_id}
          </div>
          <div>
            {el?.price} MSTR
          </div>
          {!infoItem.user_id && <div>
            {index > 0 && `${Number(Math.round((el.back_amount - el.price) * 100) / 100)} MSTR`}
          </div>}
        </>) : 'No Bid'}
      </div>
    </TabPane>)
  }, [topBids, itemUpdate])

  useEffect(() => {
    getDetail()
    getTopBid()
  }, [id])

  useEffect(() => {
    let timer: any = null;
    timer = setInterval(() => {
      let res = utils.getCountdownText(infoItem.start_time <= Date.now() ? infoItem.end_time : infoItem.start_time);
      setText(res);
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, [infoItem]);

  useEffect(() => {
    async function loadSocket() {
      let nSocket: any;
      if (socketAuction.socket?.connected === undefined) {
        nSocket = socketAuction.initiateSocket();
      } else {
        nSocket = socketAuction.socket;
      }
      nSocket.off("auction/bid");
      nSocket.on("auction/bid", async (payload: any) => {
        if (payload?.data) {
          if (payload?.data.id == id) {
            setItemUpdate(payload?.data)
            await getTopBid()
          }

        }
      });
    }
    loadSocket()
  }, []);

  useEffect(() => {
    if (infoItem?.item_data) {
      setImage(<AuctionImage itemData={{ ...infoItem.item_data, dna }} width={380} height={380} isDetail={true} />)
    }
  }, [infoItem?.item_data])

  return (
    <div className="w-full h-full bg-[#171612]  cursor-pointer min-h-screen pb-[30px]">
      <div onClick={onClickBack} className="flex pt-[34px] ml-[63px] text-white text-[14px] font-medium">
        <img className="mr-[12px]" src={require("./arrow-left.png")} alt="arrow" />
        Back
      </div>
      <div className="w-full sm:w-[1065px] mx-auto pt-0 sm:flex flex-row justify-between">
        <div className={`w-full sm:w-[380px] auction-image ${infoItem?.item_data?.decoration_list && "mt-[80px]"}`}>
          {auctionImg}
        </div>
        <div className="w-full p-[20px] sm:p-0 sm:w-[512px] mt-[100px] sm:mt-0">
          {text && (text ? <div className="w-full pb-[10px] text-[#E89F01] text-[20px]"> {infoItem.start_time <= Date.now() ? "Ends in " : "Starts in "} {(text["days"] > 0 ? text["days"] + "d " : "") + text["hours"] + ":" + text["minutes"] + ":" + text["seconds"]}</div>
            : <div className="w-full pb-[10px] text-[#E89F01] text-[20px]">Time out!</div>)}
          <div className="text-white text-4xl font-semibold mb-[30px]">{infoItem?.name}</div>
          {renderLevel()}
          <div className="my-4 flex gap-2 justify-center xl:justify-start text-white">
            <div className="self-center">Rarity:</div>
            {dna ? <img src={`/assets/images/rarity/${Rarity[dna[BodyPart.Aura][0]]?.toLowerCase()}-icon.svg`} alt="rarity" className="w-[28px] h-[28px]" />
              : (rarity !== null && <img className="w-[28px] h-[28px]" src={`/assets/images/rarity/${Rarity[rarity]?.toLowerCase()}.png`} alt="rarity" />)
            }
          </div>

          <div className="flex items-end justify-between">
            <div>
              <div className="text-white opacity-70 text-[12px] font-normal ">Current Bid: </div>
              <div className="text-white text-[36px] font-semibold flex text-center items-center mb-[14px] ">
                <img src={require("./mstr.png")} className="w-[32px] mr-[8px]" alt="mstr" />
                {itemUpdate ? itemUpdate?.price : (infoItem?.current_price > 0 ? infoItem?.current_price : infoItem?.init_price)}
              </div>
            </div>
            <div className="text-white text-[14px] font-normal flex text-center items-center mb-[14px] pb-[8px]">
              <div className="text-white opacity-70 text-[12px] font-normal mr-[12px]">Next minimum Bid:  </div>
              <img src={require("./mstr.png")} className="w-[24px] mr-[6px]" alt="mstr" />
              {itemUpdate ? Number(Math.ceil(itemUpdate?.price * 110) / 100) : (infoItem?.current_price > 0 ? Number(Math.ceil(infoItem?.current_price * 110) / 100) : Number(Math.ceil(infoItem?.init_price * 110) / 100))}
            </div>
          </div>

          {text && (user?.id ? ((infoItem?.start_time <= Date.now()) ? (infoItem?.user_id != user?.id ? <Form form={form} onFinish={createBid}>
            <div className="flex items-center justify-between px-[16px] mb-[4px] w-full h-[70px] sm:h-[56px] rounded-md border-t-2 border-[#e89f01] flex bg-white text-[#6C6D76] input-bid">
              Your Bid:
              <Form.Item className="m-0 mb-0" name="price" rules={rules.price}>
                <Input
                  onChange={onChangePrice}
                  className="text-black text-[24px] font-medium text-center"
                />
              </Form.Item>
              <img
                className="w-[40px]"
                src={require("./mstr.png")}
                alt="mstr"
              />

            </div>
            {!infoItem.user_id && <div className="text-[14px] h-[20px] opacity-70 text-white mb-[12px] mt-[4px]">
              {renderNote()}
            </div>}

            <Button
              htmlType="submit"
              loading={isLoading}
              disabled={topBids[0]?.user_id == user?.id}
              className="text-[18px] mt-[12px] w-full text-white h-[50px] bg-[#F9C744] btn-yellow flex items-center justify-center text-[18px] font-semibold text-[#E89F01]">
              {" "} {topBids[0]?.user_id == user?.id ? `You placed the last bid` : `Bid now `}
            </Button>
          </Form> : <p className="text-red text-center">You will receive 96% of the final bid</p>) : (infoItem?.user_id == user?.id && <Button
            onClick={cancelAuction}
            className="text-[18px] mt-[12px] w-full text-white h-[50px] bg-[#F9C744] btn-red flex items-center justify-center text-[18px] font-semibold text-[#E89F01]">
            {"Cancel this auction"}
          </Button>)) :
            <p className="text-red text-center">
              You're not signing in, please sign-in first!
            </p>)}
          <TabDetail renderListBids={renderListBids} infoItem={infoItem} customInfoTab={customInfoTab} detailItem={detailItem} />
        </div>
      </div>
    </div>
  );
}
