import CheckRankInput from "components/check-rank-input";
import RankItem from "components/rank-item";
import { EventEntity } from "interfaces";
import { useEffect, useState } from "react";
import { api } from "services";
export interface RankItemInterface {
  rank: number;
  customer_id: number;
  name: string;
  identity: string;
  score: number;
}
export default function EventRank({ event }: { event: EventEntity }) {
  const [rank, setRank] = useState([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [address, setAddress] = useState<string>("");
  const [error, setError] = useState<string>("");
  async function loadRankInfos() {
    try {
      setLoading(true);
      let rs = await api.post("/event/get-event-rank", { event_id: event.id });
      setRank(rs);
    } finally {
      setLoading(false);
    }
  }
  async function searchRank() {
    if (!address) {
      return await loadRankInfos();
    }
    try {
      setLoading(true);
      let rs = await api.post("/event/search-event-rank", {
        event_id: event.id,
        search: address,
      });
      setRank([rs]);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    loadRankInfos();
  }, [event]);
  return (
    <div className="p-4">
      <div className="flex justify-between w-full p-4 items-start gap-4">
        {/* <p className="text-center font-cookie text-3xl">{event.name}</p> */}
        <CheckRankInput
          onChange={(val: string) => {
            setAddress(val);
          }}
          onSearch={() => {
            searchRank();
          }}
        />
      </div>
      <div className="mt-4">
        {loading && <p className="text-center">Loading...</p>}
        {!loading && rank.map((r: any, index: number) => <RankItem data={r} />)}
      </div>
    </div>
  );
}
