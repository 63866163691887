import { utils } from "services";
import { useMemo } from "react";
import Anim from "services/anim";
import Decor from "services/decor";
import StaticAnim from "services/staticanim";
import {
  BarrackType,
  BombardType,
  CampType,
  MongenRace,
  PlotType,
  Rarity,
  RewardEntity,
  RewardType,
  TicketRarity,
  TowerType,
  TrapType,
} from "interfaces";
import { getBoxIcon } from "services/box";


const getRewardIcon = (reward: any) => {
  switch (reward.type) {
    case RewardType.LOCK_MAG:
      return "s-mag.png";
    case RewardType.UNLOCK_MAG:
      return "mag.png";
    case RewardType.DailyQuestPoint:
      return 'quest-point.webp';
    case RewardType.MSTR:
      return "mstr.png";
    case RewardType.SOULCORE:
      //@ts-ignore
      if (reward.race === -1) {
        return "soulcore-icon.svg";
      } else {
        return `egg/${MongenRace[reward.race]?.toLowerCase()}-${reward.rarity}.webp`
      }
    case RewardType.PLOT:
      switch (reward.plot_type) {
        // @ts-ignore
        case -1:
          return "plot-random.png";
        case 1:
          if (!MongenRace[reward.race ?? reward.plot_race]) {
            return "plot-2-icon.svg"
          }
          return `plots/${PlotType[
            reward.plot_type
          ]?.toLowerCase()}-${MongenRace[reward.race ?? reward.plot_race]?.toLowerCase()}-${reward.rarity
            }.webp`;
        case PlotType.Tower:
          if (reward.sub_plot_type) {
            return `plots/${TowerType[
              reward.sub_plot_type
            ]?.toLowerCase()}-${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Trap:
          if (reward.sub_plot_type) {
            return `plots/${TrapType[
              reward.sub_plot_type
            ]?.toLowerCase()}-${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Bombard:
          if (reward.sub_plot_type) {
            return `plots/${BombardType[
              reward.sub_plot_type
            ]?.toLowerCase()}-${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Barracks:
          if (reward.sub_plot_type) {
            return `plots/${BarrackType[
              reward.sub_plot_type
            ]?.toLowerCase()}-${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          }
        case PlotType.Camp:
          if (reward.sub_plot_type) {
            return `plots/${CampType[
              reward.sub_plot_type
            ]?.toLowerCase()}-${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          } else {
            return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
              }.webp`;
          }
        default:
          return `plots/${PlotType[reward.plot_type]?.toLowerCase()}-${reward.rarity
            }.webp`;
      }
    case RewardType.EVOLUTION_ITEM:
      return "evo-item-icon.svg";
    case RewardType.RerollRuneStone:
      return "rerolled-rune.png";
    case RewardType.NormalRuneStone:
      return "normal-rune.png";
    case RewardType.NormalStone:
      return "normal-stone.png";
    case RewardType.RerollStone:
      return "rerolled-stone.png";
    case RewardType.BOX:
      return getBoxIcon(reward);
    case RewardType.FOOD:
      return "food.png";
    case RewardType.EXP:
      return "exp.png";
    case RewardType.TICKET:
      switch (reward.rarity as number) {
        case TicketRarity.Common:
          return "ticket-0.png";
        case TicketRarity.Uncommon:
          return "ticket-1.png";
        case TicketRarity.Rare:
          return "ticket-2.png";
        case TicketRarity.Epic:
          return "ticket-3.png";
        case TicketRarity.Legendary:
          return "ticket-4.png";
        case TicketRarity.General:
          return 'ticket-5.png';
        case TicketRarity.Global:
          return 'ticket-6.png';
        default:
          return "question.webp";
      }
    case RewardType.TROPHY_SCORE:
      return "trophy.png";
    case RewardType.BattleFrontShield:
      return "shield.png";
    case RewardType.Energy:
      return "energy.png";
    case RewardType.DecorationList:
      return `deco/${reward.decoration_code}.webp`
    case RewardType.Pigment:
      return "pigment.png";
    case RewardType.ElectiveStoneCO:
      return "elective_co.webp";
    case RewardType.ElectiveStoneEP:
      return "elective_ep.webp";
    case RewardType.ElectiveStoneLE:
      return "elective_le.webp";
    case RewardType.ElectiveStoneRA:
      return "elective_ra.webp";
    case RewardType.ElectiveStoneUC:
      return "elective_uc.webp";
    case RewardType.ElectiveRuneStoneCO:
      return "elective_rune_co.webp";
    case RewardType.ElectiveRuneStoneEP:
      return "elective_rune_ep.webp";
    case RewardType.ElectiveRuneStoneLE:
      return "elective_rune_le.webp";
    case RewardType.ElectiveRuneStoneRA:
      return "elective_rune_ra.webp";
    case RewardType.ElectiveRuneStoneUC:
      return "elective_rune_uc.webp";
    default:
      return "question.webp";
  }
};
export default function AuctionImage({ itemData, width, height, isDetail }: { itemData: any, width: number, height: number, isDetail?: boolean }) {
  const renderImage = useMemo(() => {
    if (itemData?.dna || itemData?.dna_raw) {
      let dna = itemData.dna ? itemData.dna : itemData?.dna_raw
      dna = Array.isArray(dna) ? dna : JSON.parse(dna)
      return isDetail ? <Anim
        lock={[]}
        dna={dna}
        width={width}
        height={height}
        setAnim={null}
        anim={"Idle"}
      /> : <StaticAnim lock={[]}
        dna={dna}
      />
    } else if (itemData.decoration_list) {
      return <Decor rarity={itemData?.rarity_index ?? itemData.rarity}
        decoration_list={itemData.decoration_list}
        isDetail={true}
      />
    } else {
      if (itemData.race_index || itemData.rarity_index) {
        let reward: RewardEntity = {
          reward_type: RewardType.PLOT,
          reward_quantity: 0,
          plot_type: itemData.type_index || itemData.plot_type,
          race: itemData.race_index,
          rarity: itemData.rarity_index,
          rate: 0,
          decoration_code: "",
          box_name: "",
          sub_plot_type: itemData.sub_type_index
        }
        let src = utils.getPlotIcon(reward)
        return <img className={isDetail ? "max-w-max h-[380px]  mx-auto" : "h-[200px] mx-auto"} src={src} />
      } else return <img className={isDetail ? "max-w-max h-[380px]  mx-auto" : "h-[200px] mx-auto"} src={`img/reward/${getRewardIcon({ ...itemData, type: itemData.type ?? RewardType.PLOT })}`} /> //only plot
    }
  }, [itemData])

  return renderImage;
}
