import { EventEntity, EventGroupEntity } from "interfaces";
import { useEffect, useState } from "react";
import { api } from "services";
import EventItem from "./event-item";

export default function HomePage({ type }: { type: "home" | "completed" }) {
  const [events, setEvents] = useState<EventGroupEntity[]>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        let eType = 0;
        if (window.location.host === "monsfiesta.monsterra.io") {
          eType = 1;
        }
        let rs = await api.post("/event-group/get-all-event-groups", {
          status: type === "home" ? "new" : "completed",
          type: eType,
          domain: window.location.host,
          // domain: "event.monsterra.io",
        });
        setEvents(rs);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, [type]);
  return (
    <div className="w-full">
      <div className="fixed top-0 left-0 w-full h-full z-0">
        <img
          src={
            type === "home"
              ? require("./bg.png")
              : require("./bg-completed.png")
          }
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full z-10 relative">
        <div className="w-full flex justify-center">
          <img
            src={
              type === "home"
                ? require("./ava.png")
                : require("./ava-completed.png")
            }
            className="max-w-[586px] w-full mt-10 md:mt-24"
          />
        </div>
        <div className="lg:mt-20 w-full max-w-[600px] lg:max-w-[1110px] mx-auto p-4">
          {loading && (
            <p className="font-cookie text-3xl text-center text-[#eac17a]">
              Loading..
            </p>
          )}
          {events !== null &&
            !loading &&
            events.length > 0 &&
            events.map((e: EventGroupEntity) => (
              <EventItem event={e} key={e.id} />
            ))}
          {events !== null && !loading && events.length == 0 && (
            <p className="font-cookie text-3xl text-center text-[#eac17a]">
              No events
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
