import CheckRankInput from "components/check-rank-input";
import Config from "config";
import dayjs from "dayjs";
import Reward from "home/event-item/reward";
import { EventEntity, EventGroupEntity } from "interfaces";
import { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { api } from "services";
import Rank from "./rank";

export default function ChampionShip() {
  const [group, setGroup] = useState<EventGroupEntity>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeEvent, setActiveEvent] = useState<EventEntity>(null);
  const [openRule, setOpenRule] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [showEventReward, setShowEventReward] = useState<EventEntity>(null);
  const [showRank, setShowRank] = useState<boolean>(false);
  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        let type = 0;
        if (window.location.host === "monsfiesta.monsterra.io") {
          type = 1;
        }
        let rs = await api.post("/event-group/get-all-event-groups", {
          status: "championship",
          type,
          domain: window.location.host,
        });
        setGroup(rs[0]);
        setActiveEvent(rs[0].eventInfos[0]);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);
  function renderPrizeButton() {
    return (
      <img
        src={require("./prize.png")}
        className="h-16 mt-4 cursor-pointer mx-auto"
        onClick={() => {
          console.log(activeEvent);
          setShowEventReward(activeEvent);
        }}
      />
    );
  }
  return (
    <div className="w-full">
      <Modal
        open={showEventReward !== null}
        onClose={() => {
          setShowEventReward(null);
        }}
        closeIcon
      >
        <div className="bg-transparent">
          <div className="bg-[#1c2023] relative rounded-md ">
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              Rewards list
            </p>
            <img
              src={require("../home/event-item/modal-separate.png")}
              className="mx-auto"
              alt="separate"
            />
            <Reward
              groupInfo={group}
              showRank={showRank}
              setShowRank={setShowRank}
              rankEvent={showEventReward}
              eventId={activeEvent?.id}
            />
          </div>
        </div>
      </Modal>
      <Modal
        basic
        open={openRule}
        onClose={() => {
          setOpenRule(false);
        }}
      >
        <div className="bg-transparent">
          <div className="bg-[#0F1011] relative rounded-lg ">
            <img
              onClick={() => {
                setOpenRule(false);
              }}
              src={require("../home/event-item/btn-close-modal.png")}
              className="absolute -top-3 -right-3 cursor-pointer"
            />
            <p className="text-center text-2xl text-white font-cookie font-semibold p-6">
              Rule
            </p>
            <img
              src={require("../home/event-item/modal-separate.png")}
              className="mx-auto"
            />
            <pre className="text-white py-4 px-6">{group?.rule}</pre>
            <div className="bg-[#292929] mt-4 p-4 rounded-b-lg">
              <img
                src={require("../home/event-item/btn-close.png")}
                className="h-12 mx-auto cursor-pointer"
                onClick={() => {
                  setOpenRule(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="fixed top-0 left-0 w-full h-full z-0">
        <img src={require("./bg.png")} className="w-full h-full object-cover" />
      </div>
      <div className="w-full z-10 relative">
        <div className="w-full flex justify-center">
          <img
            src={require("./ava.png")}
            className="max-w-[500px] w-full mt-10 md:mt-24"
          />
        </div>
        <div className="w-full flex justify-center">
          <div
            style={{ backgroundImage: `url(${require("./time.png")})` }}
            className="bg-cover p-2 mt-6"
          >
            {group && (
              <div className="text-white font-semibold text-center flex flex-col lg:flex-row gap-2">
                <p>
                  Start time: {!group.start_time && "--:-- UTC ---- --"}
                  {group.start_time &&
                    dayjs(group.start_time)
                      .toDate()
                      .toUTCString()
                      .split(",")[1]}
                </p>
                -
                <p>
                  End time: {/* @ts-ignore */}
                  {!group.end_time && "--:-- UTC ---- --"}
                  {group.end_time &&
                    dayjs(group.end_time).toDate().toUTCString().split(",")[1]}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <img
            src={require("./rule-btn.png")}
            className="h-10  cursor-pointer order-1  lg:hidden"
            onClick={() => {
              setOpenRule(true);
            }}
          />
          <a href="https://play.monsterra.io" className="order-2 lg:hidden">
            <img src={require("./play-btn.png")} className="h-10" />
          </a>
        </div>
        <div className="max-w-[700px] mx-auto">
          <div className="flex justify-between mt-6 gap-10">
            <img
              src={require("./rule-btn.png")}
              className="h-10 cursor-pointer hidden lg:block"
              onClick={() => {
                setOpenRule(true);
              }}
            />
            <CheckRankInput
              onSearch={(val: string) => {
                console.log("set search ", val);
                setSearch(val);
              }}
            />

            <a
              target="_blank"
              href="https://play.monsterra.io"
              className="hidden lg:block"
            >
              <img src={require("./play-btn.png")} className="h-10 w-64" />
            </a>
          </div>
          <div className="w-full max-w-[700px] mx-auto mt-6">
            {group && (
              <div className="flex justify-between text-white text-xl font-semibold flex-col lg:flex-row gap-4">
                {group.eventInfos.map((e: EventEntity) => (
                  <div
                    className="w-full cursor-pointer"
                    onClick={() => {
                      setActiveEvent(e);
                    }}
                  >
                    <p
                      className={`${
                        activeEvent?.id === e.id &&
                        "text-[#E89F01] text-outline-orange"
                      } text-center`}
                    >
                      {e.name}
                    </p>
                    {activeEvent?.id === e.id && (
                      <img
                        src={require("./underline.png")}
                        className="w-full mt-2 h-[2px]"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          {renderPrizeButton()}
          <Rank
            eventId={activeEvent?.id}
            search={search}
            eventInfo={activeEvent}
          />
        </div>
      </div>
    </div>
  );
}
