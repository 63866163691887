import { MongenRace, Rarity, BodyPart, MongenStatType } from "interfaces";
import { utils, api } from "services";
import { Tabs } from "antd";
import { Popover } from "@headlessui/react";
import GameConstant from "interfaces/game-constants";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs

export default function TabDetail({ customInfoTab, infoItem, renderListBids, detailItem }: { customInfoTab: Function, infoItem: any, renderListBids: any, detailItem: any }) {
  const basicSkill = detailItem?.skills ? detailItem?.skills[0] : null;
  const ultimateSkill = detailItem?.skills ? detailItem?.skills[1] : null;
  const troopInfos = detailItem?.troopInfos;
  const statsData = detailItem?.details.stats;
  const decorationParts = detailItem?.attributes.find((el: any) => el.trait_type == "Decoration parts")
  const dna: any = utils.tryToParse(infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw)
  const race = infoItem?.item_data?.race || infoItem?.item_data?.race_index || infoItem?.item_data?.plot_race;
  const rarity = infoItem?.item_data?.rarity || infoItem?.item_data?.rarity_index;
  const remove_breeding = infoItem?.item_data?.remove_breeding;

  const renderLinkImage = (image: string) => {
    console.log("image", image);

    const array = image?.split("/")
    var newImage = "/assets/images"
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + '/' + array[i]
    }
    return newImage.replace("+", "_")
  }


  const renderDecor = () => {
    let valueDecoration = decorationParts?.value
    return (
      <>
        <div className="text-[18px] font-semibold mt-8">
          Decoration list
        </div>
        <div className="bg-[#1C2023] p-6 rounded-xl w-full mt-4">
          {valueDecoration?.map((el: any) => <div className=" flex mb-[12px]">
            <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
            {el.decoration_code}
          </div>)}
        </div>

        <div className="text-[18px] font-semibold mt-8">
          Stats
        </div>
        <div className="bg-[#1C2023] p-6 rounded-xl w-full mt-4">
          {valueDecoration?.map((el: any) => <div className=" flex mb-[12px]">
            <div className="w-[5px] h-[5px] rounded-[3px] bg-[#E1E2E9] m-[7px]" />
            {el.decoration_code_desc}
          </div>)}
        </div>
      </>
    )
  }
  return (
    <Tabs tabPosition="top" className="auction-tab-history">
      {renderListBids}
      <TabPane
        className={`info-tab text-white`}
        tab={customInfoTab("Description")}
        key={0}
      >
        {(infoItem?.long_description || infoItem?.description) && <div className="w-full p-[24px] bg-[#1C2023] rounded-md mt-[14px] text-[#E1E2E9] mb-[24px]">
          {infoItem?.long_description || infoItem?.description}
        </div>}
        <div className="text-[18px] font-semibold text-white ">About</div>
        {dna ?
          <div className="bg-[#1C2023] grid grid-cols-3 gap-4 p-6 rounded-xl w-full mt-4">
            <div>
              <div className="text-[#c4c4c4]">CLASS</div>
              <div className="mt-3 flex gap-2 text-lg font-bold">
                <img src={`/assets/images/race/${MongenRace[dna[BodyPart.Form][0]]?.toLowerCase()}.png`} alt="race" className="w-8 h-7" />
                {MongenRace[dna[BodyPart.Form][0]].toUpperCase()}
              </div>
            </div>
            <div>
              <div className="text-[#c4c4c4]">RARITY</div>
              <div className="mt-3 flex gap-2 text-lg font-bold">
                <img src={`/assets/images/rarity/${Rarity[dna[BodyPart.Aura][0]].toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7" />
                {Rarity[dna[BodyPart.Aura][0]].toUpperCase()}
              </div>
            </div>
            <div>
              <div className="text-[#c4c4c4]">BREED COUNT</div>
              <div className="mt-3 font-bold">
                {remove_breeding ? GameConstant.BreedingLimit[dna[BodyPart.Aura][0]] : (detailItem?.details?.about?.find((e: any) => e.name === "Children")?.value ?? 0)} / {GameConstant.BreedingLimit[dna[BodyPart.Aura][0]]}
              </div>
            </div>
          </div>
          : <div className="w-full p-[24px] bg-[#1C2023] rounded-md mt-[14px] flex mb-[23px]">
            <div className="w-1/2 text-[#C4C4C4] text-[14px]">
              Race
              <div className="text-[#F1F1F1] h-[30px] text-[14px] flex mt-[6px] items-center">
                {(race) && MongenRace[race] != "All" && <img className="w-[30px] h-[30px] mr-[12px]" src={require(`../race-icon/${MongenRace[race]?.toLowerCase()}.png`)} alt="race" />}
                {MongenRace[race]?.toUpperCase()}
              </div>
            </div>
            <div className="w-1/2 text-[#C4C4C4] text-[14px]">
              Rarity
              <div className="text-[#F1F1F1] h-[30px] text-[14px] flex mt-[6px] items-center ">
                {(rarity) && Rarity[rarity] != "All" && <img className="w-[30px] h-[30px] mr-[12px]" src={require(`../rarity-icon/${Rarity[rarity]?.toLowerCase()}.png`)} alt="rarity" />}
                {Rarity[rarity]?.toUpperCase()}
              </div>
            </div>
          </div>
        }
        {(!decorationParts) ?
          <>
            <div className="text-[18px] font-semibold mt-8">
              Stats
            </div>
            {statsData && <div className="bg-[#1C2023] grid grid-cols-2 gap-4 p-6 rounded-xl w-full mt-4">
              <div className={infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw ? "mt-5" : "mt-0"}>
                {statsData.map((statData: any) => {
                  return (
                    <div className="mb-2 flex gap-2">
                      {statData.name}
                    </div>
                  )
                })}
              </div>
              <div>
                {(infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw) && <div className="grid grid-cols-3 gap-4 text-center">
                  <div>Base</div>
                  <div>Current</div>
                  <div>Max</div>
                </div>}

                {(infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw) ? [MongenStatType.Health, MongenStatType.Sturdiness, MongenStatType.Magic, MongenStatType.Aggresion].map((type) => {
                  return (
                    statsData[type] && (
                      (infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw) ? <div className="grid grid-cols-3 gap-4 mt-1 text-center">
                        <div>{statsData[type]?.base}</div>
                        <div className="text-[#e89f01]">{statsData[type]?.value}</div>
                        <div>{statsData[type]?.max}</div>
                      </div>
                        : <div className="text-[#e89f01] mt-[5px]">{statsData[type]?.value}</div>)
                  )
                }) :
                  statsData.map((statData: any) => {
                    if (statData.name === "Troop rarities") {
                      return (
                        <div className="text-[#e89f01] mt-[5px] gap-1 flex">
                          {statData?.value?.map((rarity: string) => {
                            return (
                              <img src={`/assets/images/rarity/${rarity.toLowerCase()}.png`} className="w-6 h-6" alt="rarity" />
                            )
                          })}
                        </div>
                      )
                    }

                    return (
                      <div className="text-[#e89f01] mt-[5px]">
                        {typeof (statData.value) === 'object' ? statData.value.join(' - ') : statData.value}
                      </div>
                    )
                  })
                }


              </div>
            </div>}
          </>
          : renderDecor()}

        {basicSkill && <> <div className="text-[18px] font-semibold mt-8">
          Skills
        </div>
          <div className="bg-[#1C2023] p-6 rounded-xl w-full mt-4">
            <div>
              <div className="text-[#c4c4c4] font-bold mb-2">Basic skill</div>
              <div className="flex gap-6 flex-wrap w-full">
                {basicSkill.skillInfos?.map((skill: any) => {
                  return (
                    <Skill skill={skill} />
                  )
                })}
              </div>
            </div>
            <div className="mt-8">
              <div className="text-[#c4c4c4] font-bold mb-2">Ultimate skill</div>
              <div className="flex gap-6 flex-wrap w-full">
                {ultimateSkill.skillInfos?.map((skill: any) => {
                  return (
                    <Skill skill={skill} />
                  )
                })}
              </div>
            </div>
          </div>
        </>}

      </TabPane>
      {troopInfos && <TabPane
        className={`info-tab text-white`}
        tab={customInfoTab("Troops")}
        key={2}
      >
        {troopInfos.map((e: any) => {
          if (!e) {
            return;
          }
          return (
            <div>
              <div className="bg-[#1C2023] p-6 rounded-xl mt-4 w-full max-w-[600px]">
                <div>{e.name}</div>
                <div className="flex gap-3 mt-3">
                  <div className="flex gap-3">
                    <div className="">
                      <div className="w-[124px] h-[128px] bg-black rounded-lg relative">
                        <img src={`/assets/images/rarity/${Rarity[e.rarity].toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7 absolute right-1 top-1" />
                        <img src={utils.getTroopImage(e.code)} className="w-[124px] h-[124px] self-center justify-center pt-[4px]" alt="troop" />
                      </div>
                    </div>
                    <div className="h-[128px]">
                      {e.skills.sort((s: any) => s.type === "Basic skill" ? -1 : 1).map((s: any) => {
                        return (
                          <Skill skill={s} classSize={"w-[60px] h-[60px]"} positionClass="tooltip-top-right" />
                        )
                      })}
                    </div>
                  </div>
                  <div className="w-full max-w-[500px]">
                    <div className="justify-center self-center py-3">
                      <div className="flex gap-4 justify-between">
                        <div className="flex gap-2">
                          <img src="/assets/images/stats/hp.svg" />
                          {e.Health?.toFixed(2)}
                        </div>
                        <div className="flex gap-2">
                          <img src="/assets/images/stats/shield.svg" />
                          {e.Sturdiness?.toFixed(2)}
                        </div>
                        <div className="flex gap-2">
                          <img src="/assets/images/stats/magic.svg" />
                          {e.Magic?.toFixed(2)}
                        </div>
                        <div className="flex gap-2">
                          <img src="/assets/images/stats/intel.svg" />
                          {e.Aggression?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#2e2e2e] flex justify-between px-2 py-2 my-1 rounded-lg">
                      <div>Cost</div>
                      <div className="flex gap-1">
                        <img src="/img/reward/food.png" alt="food" className="w-6 h-6" />
                        {e.training_cost}
                      </div>
                    </div>
                    <div className="bg-[#2e2e2e] flex justify-between px-2 py-2 my-3 rounded-lg">
                      <div>Time</div>
                      <div className="flex gap-2">
                        {e.training_time} s
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-sm mt-3 bg-[#2e2e2e] p-2 px-3 rounded-lg">{e.description}</div>
              </div>
            </div>
          )
        })}

      </TabPane>}
      {(infoItem?.item_data?.dna || infoItem?.item_data?.dna_raw) && <TabPane
        className={`info-tab`}
        tab={customInfoTab("DNA")}
        key={3}
      >

        <div className="text-[18px] font-semibold text-white mt-[41px]">DNA</div>
        <div className="bg-[#21262B] grid grid-cols-2 gap-4 py-3 px-6 rounded-t-xl w-full mt-4 text-[14px] text-[#E1E2E9]">
          <div>
            Body part
          </div>
          <div>
            Self
          </div>
        </div>
        <div className="bg-[#1C2023] grid grid-cols-2 gap-4 p-6 rounded-b-xl w-full text-[14px] text-[#E1E2E9]">
          {[BodyPart.Form, BodyPart.Head, BodyPart.Eyes, BodyPart.Horns, BodyPart.Tail, BodyPart.Back].map((bodyPart) => {
            const rarity = utils.getDnaRarity(infoItem.dna[bodyPart])
            return (
              <>
                <div>
                  {BodyPart[bodyPart]}
                </div>
                <div className='flex gap-2'>
                  <img src={`/assets/images/rarity/${rarity.toLowerCase()}-icon.svg`} alt="rarity" className="w-8 h-7" />
                  {utils.getDnaName(infoItem.dna[bodyPart])}
                </div>
              </>
            )
          })}
        </div>
        {infoItem?.dna?.[BodyPart.Aura][1] && <Mark dna={infoItem.dna} />}
      </TabPane>}
    </Tabs>
  );
}

const Mark = ({ dna }: { dna: number[][] }) => {
  let markInfo = utils.getRuneData(dna[BodyPart.Aura][1])
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-[18px] font-semibold text-white mt-[41px]">Mark</div>
      <div className="bg-[#1C2023] p-6 rounded-xl w-full mt-4 text-white">
        {t(markInfo.name + "_title")}
        <div className="flex mt-[10px]">
          <img src={`/assets/images/mark/${markInfo.code}.webp`} alt="code" />
          <div className="ml-[14px] p-[10px] bg-[#2e2e2e] rounded-l w-full">
            {t(`${markInfo.name.toUpperCase()}_description`).replace(
              "{0}",
              `${markInfo.value?.toFixed(2)}`
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Skill = ({ skill, classSize, positionClass }: any) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button>
            <div className={`${classSize ? classSize : "w-[80px] h-[80px]"} ${skill.isActive && "active-skill"} cursor-pointer rounded-xl`}>
              <img src={utils.getSkillImage(skill.code)} alt="skill" className="rounded-xl" />
            </div>
          </Popover.Button>
          <Popover.Panel className={`tooltip-skill rounded-xl ${positionClass ? positionClass : "tooltip-top-left"} `}>
            <div className="text-center p-2">{skill.name}</div>
            <div className="content rounded-xl pt-4 pb-3 px-3 mx-1 mb-2">
              <div className="grid grid-cols-2">
                <div>
                  <div>
                    <img src={utils.getSkillImage(skill.code)} alt="skill" className="w-[94px] mx-auto" />
                  </div>
                  {skill.additionIcons && <div className="flex justify-center mx-auto w-full gap-[2px] mt-2 w-full mx-auto">
                    {skill.additionIcons.map((i: string) =>
                      <img src={i} alt="skill" className="w-[30px]" />
                    )}
                  </div>
                  }
                </div>
                <div>{Object.keys(skill.stats).map((key) => {
                  return (
                    <div className="flex justify-between px-6 text-[13px] mb-2 text-[#BFBFBF]">
                      <div>{key.toUpperCase()}</div>
                      <div>{skill.stats[key]}</div>
                    </div>
                  )
                })}</div>
              </div>
              <div className="desc rounded-xl py-2 px-4 text-[13px] mt-3">{skill.description}</div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}
