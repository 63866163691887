import { Dropdown, Menu, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ui, utils } from "services";
import { useState } from "react";
import { clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import { PageComponent } from "components/page-component/page-component";
import { SSO_META } from "config";
import { SingleSignon } from "components/singlesignon/singlesignon";
import { MenuIcon } from "@heroicons/react/outline";
import { CustomerInterface } from "interfaces";

export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const nav = useNavigate();
  const [clickedLogout, setClickedLogout] = useState(false);

  async function onLogoutClick() {
    setClickedLogout(true);
    await ui.confirm(t("Are you sure want to logout?"), () =>
      setClickedLogout(false)
    );
    dispatch(clearUserInfo());
    dispatch(clearAuthInfos());
    sessionStorage.clear();
    localStorage.clear();
    nav("/");
  }

  return (
    <div
      className="w-full main-header bg-black-primary"
      style={{ zIndex: "99" }}
    >
      <div className="hidden lg:block">
        <Menu inverted className="main-container" borderless>
          <Menu.Item
            onClick={() => {
              nav("/");
            }}
            header
            className="logo-header"
          >
            <img src="/assets/images/logo.png" alt="logo" />
          </Menu.Item>
          <PageComponent SSO_META={SSO_META()} />
          <Menu.Item>
            <Link to="/">
              <p className="hover:text-[#E89F01]">New Events</p>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/completed">
              <p className="hover:text-[#E89F01]">Completed Events</p>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/championship">
              <p className="hover:text-[#E89F01]">Championship</p>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/referral">
              <p className="hover:text-[#E89F01]">Referral</p>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/raffle">
              <p className="hover:text-[#E89F01]">Raffle</p>
            </Link>
          </Menu.Item>
          <Dropdown item text="Auction" in>
            <Dropdown.Menu className="dark-dropdown">
              <Dropdown.Item>
                <Link to="/auction-b2c">
                  <p className="hover:text-[#E89F01]">B2C Auction</p>
                </Link>
              </Dropdown.Item>
              <Menu.Item>
                <Link to="/auction-p2p">
                  <p className="hover:text-[#E89F01]">P2P Auction</p>
                </Link>
              </Menu.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown item text="Lottery" in>
            <Dropdown.Menu className="dark-dropdown">
              <Dropdown.Item>
                <Link to="/racing">
                  <p className="hover:text-[#E89F01]">Racing</p>
                </Link>
              </Dropdown.Item>
              <Menu.Item>
                <Link to="/wheel">
                  <p className="hover:text-[#E89F01]">Lucky Wheel</p>
                </Link>
              </Menu.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Menu inverted position="right">
            {user?.id > 0 && (
              <Menu.Item className="hoverless">
                <div className="rounded-lg border border-grey-primary overflow-hidden flex bg-black-linear ">
                  <div className="text-center col-span-6 grid grid-cols-11 py-3 px-3">
                    <div className="col-span-5 mr-auto">
                      <img
                        src="/assets/images/items/mstr-icon.svg"
                        alt="mstr"
                        className="inline-block mr-1"
                      />
                      <span className="secondary-font-medium text-sm">
                        {utils.convertToInternationalCurrencySystem(
                          user?.[CustomerInterface.mstr]
                        ) ?? "--"}
                      </span>
                    </div>
                    <div
                      className="vr col-span-1 mt-3"
                      style={{ height: "1px" }}
                    ></div>
                    <div className="col-span-5 mr-auto text-sm">
                      <img
                        src="/assets/images/items/mag-icon.svg"
                        alt="mag"
                        className="inline-block mr-1"
                      />
                      <span className="secondary-font-medium text-sm">
                        {utils.convertToInternationalCurrencySystem(
                          user?.[CustomerInterface.staked_mag]
                        ) ?? "--"}
                      </span>
                    </div>
                  </div>
                  <Popup
                    position="bottom center"
                    style={{ padding: "unset", border: "unset" }}
                    hoverable
                    trigger={
                      <div className="text-center col-span-4 flex px-3 pt-0.5">
                        <div className="mt-3.5">
                          Hello {utils.formatName(user?.name)}
                        </div>
                        <img
                          src="/assets/images/icons/select-icon.svg"
                          alt="select"
                          className="ml-auto h-8 w-8 mt-2"
                        />
                      </div>
                    }
                    content={
                      clickedLogout ? (
                        <></>
                      ) : (
                        <div className="">
                          <div className="max-w-full py-4 bg-black-linear-secondary"></div>
                          <span
                            className="flex bg-black-linear-3 py-4 px-5 pl-10 text-white-light cursor-pointer hover-orange"
                            onClick={onLogoutClick}
                          >
                            <img
                              src="/assets/images/icons/logout-icon.svg"
                              alt="logout"
                              className="h-6 w-6 mr-2"
                            />{" "}
                            Logout
                          </span>
                        </div>
                      )
                    }
                    basic
                  />
                </div>
              </Menu.Item>
            )}
            {!user?.id && (
              <>
                <Menu.Item className="hoverless" key="popup signin">
                  <SingleSignon
                    type="signin"
                    device="laptop"
                    SSO_META={SSO_META()}
                  />
                </Menu.Item>
              </>
            )}
          </Menu.Menu>
        </Menu>
      </div>

      <div className="lg:hidden w-full">
        <Menu inverted={true} borderless className="header-mb">
          <Menu.Item
            onClick={() => {
              nav("/");
            }}
            header
            className="logo-header"
            key="mb-logo"
          >
            <img src="/assets/images/logo.png" alt="logo" />
          </Menu.Item>
          <PageComponent SSO_META={SSO_META()} />
          {!user?.id ? (
            <Menu.Item
              className="hoverless flex justify-right self-right"
              id="mbsignin"
              key="mbsignin"
              position="right"
              style={{ justifyContent: "right !important" }}
            >
              <SingleSignon
                type="signin"
                device="mobile"
                SSO_META={SSO_META()}
              />
            </Menu.Item>
          ) : (
            <Menu.Item position="right" key="mb-item-menu">
              <Popup
                hoverable
                position="bottom right"
                style={{ padding: "unset", border: "unset", maxWidth: "100vw" }}
                trigger={
                  <div className="text-center col-span-4 bg-black-linear-4 flex px-3 pt-0.5">
                    <MenuIcon className="w-7 h-8 mb-1" />
                  </div>
                }
                content={
                  clickedLogout ? (
                    <></>
                  ) : (
                    <>
                      <div className="w-screen self-center max-w-[600px] py-4 px-10 bg-black-linear-secondary fixed relative top-0">
                        {user?.id > 0 && (
                          <Menu
                            inverted={true}
                            className="navbar-md flex flex-wrap"
                            borderless
                            key="mb-menu"
                          >
                            <Menu.Item>
                              <Link to="/">
                                <p className="hover:text-[#E89F01]">
                                  New Events
                                </p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/completed">
                                <p className="hover:text-[#E89F01]">
                                  Completed Events
                                </p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/championship">
                                <p className="hover:text-[#E89F01]">
                                  Championship
                                </p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/referral">
                                <p className="hover:text-[#E89F01]">Referral</p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/raffle">
                                <p className="hover:text-[#E89F01]">Raffle</p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/racing">
                                <p className="hover:text-[#E89F01]">Racing</p>
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link to="/wheel">
                                <p className="hover:text-[#E89F01]">
                                  Lucky Wheel
                                </p>
                              </Link>
                            </Menu.Item>
                          </Menu>
                        )}
                        {user?.id > 0 && (
                          <Menu.Item className="hoverless">
                            <div className="text-center col-span-6 grid grid-cols-11 py-3 px-3 text-white">
                              <div className="col-span-5 mr-auto">
                                <img
                                  src="/assets/images/items/mstr-icon.svg"
                                  alt="mstr"
                                  className="inline-block mr-1"
                                />
                                <span className="secondary-font-medium text-sm">
                                  {utils.convertToInternationalCurrencySystem(
                                    user?.[CustomerInterface.mstr]
                                  ) ?? "--"}
                                </span>
                              </div>
                              <div
                                className="vr col-span-1 mt-3"
                                style={{ height: "1px" }}
                              ></div>
                              <div className="col-span-5 mr-auto text-sm">
                                <img
                                  src="/assets/images/items/mag-icon.svg"
                                  alt="mag"
                                  className="inline-block mr-1"
                                />
                                <span className="secondary-font-medium text-sm">
                                  {utils.convertToInternationalCurrencySystem(
                                    user?.[CustomerInterface.staked_mag]
                                  ) ?? "--"}
                                </span>
                              </div>
                            </div>
                            <div className="rounded-lg border border-grey-primary overflow-hidden">
                              <>
                                <div className="max-w-full py-4 bg-black-linear-secondary"></div>
                                <span
                                  className="flex bg-black-linear-3 py-4 px-10 text-white-light cursor-pointer hover-orange"
                                  onClick={onLogoutClick}
                                >
                                  <img
                                    src="/assets/images/icons/logout-icon.svg"
                                    alt="logout"
                                    className="h-6 w-6 mr-2"
                                  />{" "}
                                  Logout
                                </span>
                              </>
                            </div>
                          </Menu.Item>
                        )}
                      </div>
                    </>
                  )
                }
                basic
              />
            </Menu.Item>
          )}
        </Menu>
      </div>
    </div>
  );
}
