import { useEffect, useState } from "react";
import { Button, Modal, } from "semantic-ui-react";
import { Popover } from "@headlessui/react";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { t } from "i18next";
import { LoginType, SSO_TYPE } from "interfaces";
import { api, ui } from "services";
import { signInWithApple, signInWithGoogle } from "services/firebase";
import { ArtButton } from "components/button";
import { store } from "reducer/store";
import { setLoginType } from "reducer/logintype.slice";

export const SingleSignon = ({ type, device, SSO_META }: { type: "signin" | "signup", device: "laptop" | "mobile", SSO_META: SSO_TYPE }) => {
    const [isMetaMask, setIsMetaMask] = useState(true);
    const [openPopup, setOpenPopup] = useState("")
    const MainnetUnsupportedChain = [LoginType.Terra];

    const supportedAuthData = [
        {
            color: "#66452b",
            onSignInClick: () => onMetaMaskClick(false, LoginType.BSC),
            authName: "BNB Chain",
            authExtension: "MetaMask",
            icon: "./assets/wallets/bsc.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: SSO_META.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LoginType.BSC)
        },
        {
            color: "#814441",
            onSignInClick: () => onMetaMaskClick(false, LoginType.Avalanche),
            authName: "AVAX Network",
            authExtension: "MetaMask",
            icon: "./assets/wallets/avalanche.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: SSO_META.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LoginType.Avalanche)
        },
        {
            color: "#4a689a",
            onSignInClick: () => onMetaMaskClick(false, LoginType.Okex),
            authName: "OKX Chain",
            authExtension: "MetaMask",
            icon: "./assets/wallets/okex.png",
            //@ts-ignore
            status: !!window.ethereum,
            extensionLink: SSO_META.MetamaskExtension,
            disabled: MainnetUnsupportedChain.includes(LoginType.Okex)
        },
        {
            color: "#596189",
            onSignInClick: () => ui.openEmailForm(
                loginByEmail,
                "Sign in"
            ),
            authName: "Email & Password",
            authExtension: "MetaMask",
            icon: "./assets/social/email.png",
            //@ts-ignore
            status: true,
            extensionLink: "",
            disabled: MainnetUnsupportedChain.includes(LoginType.Email)
        },
    ]

    const supportedSocialAuth = {
        "Facebook": {
            color: "#344763",
            onSignInClick: async (accessToken: string) => { await onSocialClick(false, accessToken, LoginType.Facebook) },
            authName: "Facebook",
            icon: "./assets/social/facebook.png",
        },
        "Google": {
            color: "#441f21",
            onSignInClick: async (accessToken: string) => { await onSocialClick(false, accessToken, LoginType.Google) },
            authName: "Google",
            icon: "./assets/social/google.png",
        },
        "Apple": {
            color: "#4b4a67",
            onSignInClick: async (idToken: string) => { await onSocialClick(false, idToken, LoginType.Apple) },
            authName: "Apple",
            icon: "./assets/social/apple.png",
        },
        // "Email": {
        //     color: "#596189",
        //     onSignInClick: () => ui.openEmailForm(
        //         loginByEmail,
        //         "Sign in"
        //     ),
        //     authName: "Email & password",
        //     icon: "./assets/social/email.png",
        //     status: true,
        //     disabled: MainnetUnsupportedChain.includes(LoginType.Username)
        // }
    }

    const onMetaMaskClick = async (isRegister = false, type: LoginType) => {
        if (isMetaMask) {
            try {
                await api.login(type);
                setOpenPopup("");
                store.dispatch(setLoginType(type));
            } catch (err: any) {
                ui.alertFailed("", t(err.message))
            }
        } else {
            window.open(
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
                "_blank"
            );
        }
    }

    const onSocialClick = async (isRegister = false, accessToken: string, loginType: LoginType) => {
        try {
            await api.login(loginType, accessToken);
            setOpenPopup("");
        } catch (err: any) {
            ui.alertFailed("", t(err.message))
        }
    }

    const loginByEmail = async (email: string, password: string) => {
        api.login(LoginType.Email, null, email, password).then(() => {
            setOpenPopup("");
        }).catch((err: any) => {
            ui.alertFailed("", t(err.message))
        });
    }

    useEffect(() => {
        if (
            //@ts-ignore
            typeof window.ethereum !== "undefined" ||
            //@ts-ignore
            typeof window.web3 !== "undefined"
        ) {
            setIsMetaMask(true);
        } else {
            setIsMetaMask(false);
        }
    }, []);

    if (device === 'mobile') {
        return (
            <>
                <ArtButton color={type === "signup" ? "red" : "yellow"} className="w-[84px]"
                    onClick={() => {
                        setOpenPopup(type)
                    }}
                >
                    {type === "signup" ? "Sign up" : "Sign in"}
                </ArtButton>
                <Modal
                    open={openPopup === type}
                    style={{ width: "unset" }}
                    closeIcon={
                        <div className="text-red cursor-pointer float-right translate-x-2 -translate-y-2">
                            <img src={require(`./assets/close-icon.png`)} alt="close" className="" />
                        </div>
                    }
                    onClose={() => {
                        setOpenPopup("");
                    }}
                >
                    <div
                        className="w-max-max md:w-[600px] hoverless"
                    >
                        <div className="bg-black-linear-secondary py-3 md:py-6 px-8">
                            <div className="text-center mb-5 text-white text-lg font-semibold">
                                Continue with
                            </div>
                            {supportedAuthData.map((authData: any, index: number) => {
                                return (
                                    <div className="my-3 relative" key={`auth-${index}`}>
                                        {authData.disabled && <div className='text-sm text-coming-soon absolute text-right w-full mt-[15px] pr-5'>Coming soon</div>}
                                        <Button fluid
                                            style={{
                                                background: authData.color,
                                                padding: "0 1rem",
                                            }}
                                            onClick={() => {
                                                if (authData.isDevelop) {
                                                    ui.alertFailed(t("feature_under_development"), "")
                                                    return;
                                                }
                                                if (authData.status) {
                                                    if (type === "signup") {
                                                        authData.onRegisterClick();
                                                    } else {
                                                        authData.onSignInClick();
                                                    }
                                                } else {
                                                    window.open(authData.extensionLink, "_blank");
                                                }
                                            }}
                                            disabled={authData.disabled}
                                        >
                                            <div className="flex relative">
                                                <img src={require(`${authData.icon}`)} className="float-left w-10 h-10 flex self-center" alt="phantom" />
                                                <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                                    <div className="text-lg text-white font-medium">
                                                        {type === "signup" ? "Sign up" : "Sign in"} with {authData.authName}
                                                    </div>
                                                    {authData?.authExtension && !authData?.status && <div className="text-sm text-red-2">
                                                        Download {authData.authExtension} extension
                                                    </div>}
                                                </div>
                                                {!authData.disabled && <div className='text-sm text-ready absolute text-right w-full mt-[15px]'>Ready</div>}
                                            </div>
                                        </Button>
                                    </div>
                                )
                            })}

                            <div className="text-red text-center mt-4 mb-3">
                                Extension of selected wallet will be installed
                            </div>
                            <div className="br mb-4"></div>

                            <div className="grid grid-cols-3 gap-4">
                                <ReactFacebookLogin
                                    cookie={true}
                                    appId={SSO_META.FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={async (res: any) => {
                                        let accessToken = res.accessToken;
                                        await supportedSocialAuth.Facebook.onSignInClick(accessToken);
                                    }}
                                    onFailure={console.log}
                                    render={(renderProps: any) => (
                                        <Button fluid
                                            style={{
                                                background: supportedSocialAuth.Facebook.color,
                                                padding: "0 0.5rem",
                                            }}
                                            onClick={() => {
                                                console.log("debug")
                                                renderProps.onClick()
                                            }}
                                        >
                                            <div className="flex">
                                                <img src={require(`${supportedSocialAuth.Facebook.icon}`)} className="float-left w-10 h-10 flex self-center" alt="facebook" />
                                                <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                                    <div className="text-lg text-white font-medium">
                                                        {type === "signup" ? "Sign up" : "Sign in"}
                                                    </div>
                                                </div>
                                            </div>
                                        </Button>
                                    )}
                                />

                                <Button fluid
                                    style={{
                                        background: supportedSocialAuth.Google.color,
                                        padding: "0 0.5rem",
                                    }}
                                    onClick={async () => {
                                        let res = await signInWithGoogle();
                                        let credential = res._tokenResponse;
                                        let accessToken = credential.oauthAccessToken;
                                        supportedSocialAuth.Google.onSignInClick(accessToken);
                                    }}
                                >
                                    <div className="flex">
                                        <img src={require(`${supportedSocialAuth.Google.icon}`)} className="float-left w-10 h-10 flex self-center" alt="google" />
                                        <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                            <div className="text-lg text-white font-medium">
                                                {type === "signup" ? "Sign up" : "Sign in"}
                                            </div>
                                        </div>
                                    </div>
                                </Button>

                                <Button fluid
                                    style={{
                                        background: supportedSocialAuth.Apple.color,
                                        padding: "0 0.5rem",
                                    }}
                                    onClick={async () => {
                                        let res = await signInWithApple();
                                        let idToken = res.idToken;
                                        supportedSocialAuth.Apple.onSignInClick(idToken);
                                    }}
                                >
                                    <div className="flex">
                                        <img src={require(`${supportedSocialAuth.Apple.icon}`)} className="float-left w-10 h-10 flex self-center" alt="google" />
                                        <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                            <div className="text-md text-white font-medium">
                                                {type === "signup" ? "Sign up" : "Sign in"}
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

    return (
        <>
            <Popover>
                {({ open }) => (
                    <>
                        <Popover.Button>
                            <span>
                                <span className="pr-6 mr-4">
                                    <ArtButton color={type === "signup" ? "red" : "yellow"}
                                        onClick={() => {
                                            setOpenPopup(type)
                                        }}
                                    >
                                        {type === "signup" ? "Sign up" : "Sign in"}
                                    </ArtButton>
                                </span>
                            </span>
                        </Popover.Button>
                        <Popover.Panel>
                            <div className="w-[520px] py-6 px-10 bg-black-linear-secondary absolute -translate-x-[430px] my-4 hoverless">
                                <div className="text-center mb-5 text-white text-lg font-semibold">
                                    Continue with
                                </div>
                                {supportedAuthData.map((authData: any, index: number) => {
                                    return (
                                        <div className="my-3 relative" key={index}>
                                            {authData.disabled && <div className='text-sm text-coming-soon absolute text-right w-full mt-[15px] pr-5'>Coming soon</div>}
                                            <Button fluid
                                                style={{
                                                    background: authData.color,
                                                    padding: "0 1rem",
                                                }}
                                                onClick={() => {
                                                    if (authData.isDevelop) {
                                                        ui.alertFailed(t("feature_under_development"), "")
                                                        return;
                                                    }
                                                    if (authData.status) {
                                                        if (type === "signup") {
                                                            authData.onRegisterClick();
                                                        } else {
                                                            authData.onSignInClick();
                                                        }
                                                    } else {
                                                        window.open(authData.extensionLink, "_blank");
                                                    }
                                                }}
                                                disabled={authData.disabled}
                                            >
                                                <div className="flex relative">
                                                    <img src={require(`${authData.icon}`)} className="float-left w-10 h-10 flex self-center" alt="phantom" />
                                                    <div className="flex flex-col relative ml-12 justify-center h-min-32">
                                                        <div className="text-lg text-white font-medium">
                                                            {type === "signup" ? "Sign up" : "Sign in"} with {authData.authName}
                                                        </div>
                                                        {!authData.disabled && authData?.authExtension && !authData?.status && <div className="text-sm text-red-2">
                                                            Download {authData.authExtension} extension
                                                        </div>}
                                                    </div>
                                                    {!authData.disabled && <div className='text-sm text-ready absolute text-right w-full mt-[15px]'>Ready</div>}
                                                </div>
                                            </Button>
                                        </div>
                                    )
                                })}

                                <div className="text-red text-center mt-4 mb-3">
                                    Extension of selected wallet will be installed
                                </div>
                                <div className="br mb-4"></div>

                                <div className="grid grid-cols-3 gap-4">
                                    <ReactFacebookLogin
                                        cookie={true}
                                        appId={SSO_META.FACEBOOK_APP_ID}
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={async (res: any) => {
                                            let accessToken = res.accessToken;
                                            await supportedSocialAuth.Facebook.onSignInClick(accessToken);
                                        }}
                                        onFailure={console.log}
                                        render={(renderProps: any) => (
                                            <Button fluid
                                                style={{
                                                    background: supportedSocialAuth.Facebook.color,
                                                    padding: "0 0.5rem",
                                                }}
                                                onClick={() => {
                                                    renderProps.onClick()
                                                }}
                                            >
                                                <div className="flex">
                                                    <img src={require(`${supportedSocialAuth.Facebook.icon}`)} className="float-left w-10 h-10 flex self-center" alt="phantom" />
                                                    <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                                        <div className="text-lg text-white font-medium">
                                                            {type === "signup" ? "Sign up" : "Sign in"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        )}
                                    />

                                    <Button fluid
                                        style={{
                                            background: supportedSocialAuth.Google.color,
                                            padding: "0 0.5rem",
                                        }}
                                        onClick={async () => {
                                            let res = await signInWithGoogle();
                                            let credential = res._tokenResponse;
                                            let accessToken = credential.oauthAccessToken;
                                            supportedSocialAuth.Google.onSignInClick(accessToken);
                                        }}
                                    >
                                        <div className="flex">
                                            <img src={require(`${supportedSocialAuth.Google.icon}`)} className="float-left w-10 h-10 flex self-center" alt="phantom" />
                                            <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                                <div className="text-lg text-white font-medium">
                                                    {type === "signup" ? "Sign up" : "Sign in"}
                                                </div>
                                            </div>
                                        </div>
                                    </Button>

                                    <Button fluid
                                        style={{
                                            background: supportedSocialAuth.Apple.color,
                                            padding: "0 0.5rem",
                                        }}
                                        onClick={async () => {
                                            let res = await signInWithApple();
                                            let idToken = res.idToken;
                                            supportedSocialAuth.Apple.onSignInClick(idToken);
                                        }}
                                    >
                                        <div className="flex">
                                            <img src={require(`${supportedSocialAuth.Apple.icon}`)} className="float-left w-10 h-10 flex self-center" alt="google" />
                                            <div className="flex flex-col relative mx-auto justify-center h-min-32">
                                                <div className="text-md text-white font-medium">
                                                    {type === "signup" ? "Sign up" : "Sign in"}
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
        </>
    );
}